<template lang="">
  <div>
    <!-- Start Filter -->
    <b-card v-if="showFilter">
      <h1>Filter</h1>
      <b-row>
        <b-col cols="8">
          <form-v-select
            ref="vendor"
            v-model="vendor"
            dataurl="vendor"
            col-label="3"
            label="Vendor"
            placeholder="Semua Vendor"
            :item_text="'name'"
          />
        </b-col>
        <b-col cols="8">
          <b-form-group
            label-cols-md="3"
            label="Tanggal"
          >
            <b-input-group>
              <flat-pickr
                v-model="date_start"
                class="form-control"
                placeholder="Tanggal Awal"
              />
              <b-input-group-append is-text>
                To
              </b-input-group-append>
              <flat-pickr
                v-model="date_end"
                class="form-control"
                placeholder="Tanggal Akhir"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="8">
          <b-form-group
            label-cols-md="3"
            label="Status"
          >
            <b-form-select
              v-model="status"
              :options="[
                { value: null, text: 'Semua Status' },
                { value:'created',text:'Pengajuan'},
                { value:'approved',text:'Disetujui'},
                { value:'rejected',text:'Selesai'},
              ]"
            />
          </b-form-group>
        </b-col>
        <b-col cols="8">
          <b-form-group
            label-cols-md="3"
            label=""
          >
            <b-button
              class="mr-1"
              size="sm"
              variant="primary"
              type="button"
              @click.prevent="show"
            >Show</b-button>
            <b-button
              class="mr-1"
              size="sm"
              variant="outline-secondary"
              type="button"
              @click.prevent="reset"
            >Reset</b-button>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <!-- End Of Filter -->
    <b-card>
      <basetable
        ref="basetable"
        :dataurl="dataurl"
        :fields="fields"
        :baseroute="baseroute"
        :title="title"
        :filter="filter"
        :is-edit="false"
        :is-delete="false"
        :is-add="jobTitle.settings.includes('vehicle.maintenance.create')"
      >
        <template v-slot:buttonaddon>
          <b-button
            class="mr-1"
            size="sm"
            variant="success"
            @click="showFilter =!showFilter"
          >
            <feather-icon icon="FilterIcon" />
            Filter
          </b-button>
          <!-- <b-button
            class="mr-1"
            size="sm"
            variant="warning"
          >
            Export
          </b-button> -->
        </template>
        <template #cell(status)="data">
          <span
            v-if="data.item.status == 'created'"
            class="badge bg-warning"
          >Pengajuan</span>
          <span
            v-else-if="data.item.status == 'approved'"
            class="badge bg-primary"
          >Disetujui</span>
          <span
            v-else-if="data.item.status == 'rejected'"
            class="badge bg-danger"
          >Selesai</span>
          <span
            v-else
            class="badge bg-info"
          >Status tidak di ketahui</span>
        </template>
      </basetable>
    </b-card>
  </div>
</template>
<script>
import basetable from '@/views/base/BaseTable.vue'
import { BCard,BButton,BFormGroup,BFormSelect,BRow,BCol, BInputGroup, BInputGroupAppend } from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import FormVSelect from '@/views/base/form/FormVSelect.vue'
import moment from 'moment'

export default {
  components: {
    basetable,
    BCard,
    BButton,
    BFormGroup,
    BFormSelect,
    BRow,
    BCol,
    FormVSelect,
    flatPickr,
    BInputGroup,
    BInputGroupAppend
  },
  data() {
    return {
      dataurl:"/vehicle-change",
      baseroute:"vehicle-change",
      title:"Daftar Penggantian Unit",
      fields: [
        { key: 'number', label: 'Kode', sortable: true },
        { key: 'spb.number', label: 'Nomor SPB', sortable: true },
        { key: 'spb.vendor.name', label: 'Vendor', sortable: true },
        { key: 'request_date', label: 'Tgl Pengajuan', sortable: true },
        // { key: 'realisation_date', label: 'Tgl Realisasi', sortable: true },
        { key: 'status', label: 'Status', sortable: true },
      ],
      showFilter : false,
      filter :[],
      vendor:null,
      date_start:"",
      date_end:"",
      status:null,
      jobTitle: {
        settings: [],
      },
      userType:null,
      user:null,
    }
  },
  created(){
      if (this.$store.state.profile.info) {
        this.userType =  this.$store.state.profile.info.contact_type.split('\\').pop().toLowerCase()
        this.user = this.$store.state.profile.info.contact
      }
      this.dataurl = this.userType == 'vendor' ? "/vehicle-change?contact="+this.user.id : "/vehicle-change"
      if(this.$route.meta.baseroute)
        this.baseroute = this.$route.meta.baseroute
  },
  mounted() {
      this.fetchData();
  },
  methods:{
    fetchData(){
        this.$http.get('auth/me').then( res => {
          for (let setting of res.data.contact.job_title.settings)
          {
            if (setting.approval && setting.module){
              this.jobTitle.settings.push(setting.module.code);
            }
          }
        })
    },
    show(){
      this.filter['filter[vehicle_changes.status]']=this.status
      this.filter['filter[spbs.vendor_id]']=this.vendor?this.vendor.id:null
      if(this.date_start != "" && this.date_end != "")
        this.filter['between[request_date]']=this.date_start+","+this.date_end

      this.$refs.basetable.fetchData()
    },
    reset(){
      this.vendor = null
      this.status = null
      this.date_start = ""
      this.date_end = ""
      this.filter['between[request_date]'] = null
      this.show()
    }
  }
}
</script>
<style lang="">

</style>
